import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {
    ElementRow,
    ElementColumn,
    ElementContainer,
    ElementInfo,
    ElementHr,
    ChannelTitle,
    VideoRecommended,
    VideoDetails,
    PlayerVod,
    PlayerPayment,
    VideosPlans
} from 'core/components'
import {Theme} from 'core/style'
import {Timelines, Comments} from '@eyecons/storybook'
import {Fetcher} from 'ra-fetch'
import {usePlayerQuality} from 'core/hooks'
import {is_paid} from 'core/functions'

const Player = ({video, user, setUser, paid, setPaid, hls, setPlayable}) => {
    if (is_paid(video)) {
        return <PlayerPayment
            user={user}
            setUser={setUser}
            paid={paid}
            setPaid={setPaid}
            setPlayable={setPlayable}
            hls={hls}
            autoplay
        />
    }

    return <PlayerVod user={user} hls={hls} autoplay/>
}

const Show = ({user, user_loading, preview, setUser, video, clubVideos, competitionVideos}) => {
    const [paid, setPaid] = useState({
        ...Fetcher.model(),
        data: {owns: preview || !is_paid(video)},
        loading: !!user.id,
    })
    const [playable, setPlayable] = useState(!is_paid(video))
    const [PlayerQuality, hls] = usePlayerQuality(video, playable)

    if (user_loading) {
        return null
    }

    return <ElementContainer className={'video-show-container'} page-level-margin>
        <ElementRow style={Theme.VideoPlayer}>
            <ElementColumn md={7} lg={8} style={{zIndex: 1, visibility: paid.loading ? 'hidden' : 'visible'}} className={'video-player-column'}>
                <div id={'video-player'} className={'video-player-container block-level-margin'}>
                    {playable && <Timelines.CardsVideoLayer id={'jwplayer-player'} mediaId={video.external_id}/>}
                    <Player
                        video={video}
                        user={user}
                        setUser={setUser}
                        paid={paid}
                        setPaid={setPaid}
                        setPlayable={setPlayable}
                        hls={hls}
                    />
                </div>

                {video.has_public_plans && !paid.data.owns && <VideosPlans params={{video_id: video.id}}/>}

                <VideoDetails user={user} user_loading={user_loading} video={video}/>

                <PlayerQuality/>

                <ElementHr/>

                {
                    !user_loading &&
                    Boolean(video.comments) &&
                    <Comments.Overview user={user} video={video}/>
                }

                <ElementInfo>
                    <ChannelTitle disable_cdn channel={{
                        ...video.channel,
                        link: {
                            to: 'channels/show',
                            params: {id: video.channel.name},
                        },
                    }} size={'h4'}/>
                </ElementInfo>
            </ElementColumn>
            <VideoRecommended
                md={5}
                lg={4}
                details={true}
                video={video}
                clubVideos={clubVideos}
                competitionVideos={competitionVideos}
            />
        </ElementRow>
    </ElementContainer>
}

export default connect(state => {
    const video = state.video
    const recommended = video && video.recommended ? video.recommended : {}

    return {
        video,
        clubVideos: recommended.club || [],
        competitionVideos: recommended.competition || [],
    }
})(Show)
